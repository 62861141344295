import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const testimonials = [
  {
    text: "Dr. Chandna's teaching approach has transformed my son's understanding of science. Her interactive sessions and practical demonstrations have made complex concepts easy to grasp. Aarav's grades have improved significantly!",
    parent: "Aarav Mehta's Parent"
  },
  {
    text: "My daughter Riya was struggling with chemistry, but Dr. Chandna's patient guidance and structured teaching methods have helped her develop a strong foundation. Now she aims to pursue research in chemistry!",
    parent: "Riya Patel's Parent"
  },
  {
    text: "The way Dr. Ohri connects scientific concepts to real-life examples is remarkable. Arjun has developed a genuine curiosity for science under her mentorship.",
    parent: "Arjun Kumar's Parent"
  },
  {
    text: "Kavya's understanding of biology has improved tremendously since joining Atomic Classes. Dr. Chandna's detailed explanations and visual teaching aids make learning enjoyable.",
    parent: "Kavya Singh's Parent"
  },
  {
    text: "Your teaching style is top-notch! My son used to struggle with grasping scientific concepts, but your clear explanations and hands-on experiments have made a world of difference. Thank you for nurturing his love for science!",
    parent: "Siddharth Verma's Parent"
  },
  {
    text: "Aanya's confidence in solving complex problems has grown immensely. Dr. Chandna's systematic approach to teaching has made a significant difference.",
    parent: "Aanya Jindal's Parent"
  },
  {
    text: "My daughter looks forward to every science class with you. Your use of real-life examples and interactive experiments has brought the subject to life. She has developed a true passion for science, thanks to you!",
    parent: "Neetu Gulati's Parent"
  }
];

const TestimonialsSection = ({ isMobile, currentTestimonial, isTestimonialFading, handleTestimonialChange }) => (
  <section id="testimonials" className="py-16 bg-gradient-to-r from-purple-100 to-blue-100">
    <div className="container mx-auto px-6">
      <h2 className="text-3xl font-bold text-center mb-12">What Parents Say</h2>
      {isMobile ? (
        <div className="max-w-3xl mx-auto relative">
          <button
            onClick={() => handleTestimonialChange('prev')}
            className="absolute left-0 top-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg z-10"
          >
            <ChevronLeft className="text-purple-600" />
          </button>
          <div
            className={`bg-white rounded-xl shadow-lg p-8 mx-12 transition-all duration-300 ease-in-out ${
              isTestimonialFading ? 'opacity-0 transform -translate-y-4' : 'opacity-100 transform translate-y-0'
            }`}
          >
            <p className="text-lg italic mb-4">{testimonials[currentTestimonial].text}</p>
            <p className="text-gray-600">- {testimonials[currentTestimonial].parent}</p>
          </div>
          <button
            onClick={() => handleTestimonialChange('next')}
            className="absolute right-0 top-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg z-10"
          >
            <ChevronRight className="text-purple-600" />
          </button>
        </div>
      ) : (
        <div className="grid md:grid-cols-3 gap-6">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-lg p-8 transform hover:scale-105 transition-transform"
            >
              <p className="text-lg italic mb-4">{testimonial.text}</p>
              <p className="text-gray-600">- {testimonial.parent}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  </section>
);

export { testimonials };
export default TestimonialsSection;