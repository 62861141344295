import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const quotes = [
  {
    text: "The important thing is to never stop questioning.",
    author: "Albert Einstein"
  },
  {
    text: "Science is not only compatible with spirituality; it is a profound source of spirituality.",
    author: "Carl Sagan"
  },
  {
    text: "The science of today is the technology of tomorrow.",
    author: "Edward Teller"
  },
  {
    text: "Every brilliant experiment, like every great work of art, starts with an act of imagination.",
    author: "Jonah Lehrer"
  },
  {
    text: "In science, the best ideas emerge from the collision of different perspectives.",
    author: "Neil deGrasse Tyson"
  },
  {
    text: "The most exciting phrase to hear in science is not 'Eureka!' but 'That's funny...'",
    author: "Isaac Asimov"
  }
];

const QuotesSection = ({ isMobile, currentQuote, isQuoteFading, handleQuoteChange }) => (
  <section className="py-16 bg-gradient-to-r from-blue-100 to-purple-100">
    <div className="container mx-auto px-6">
      <h2 className="text-3xl font-bold text-center mb-12">Inspiring Words</h2>
      {isMobile ? (
        <div className="max-w-3xl mx-auto relative">
          <button
            onClick={() => handleQuoteChange('prev')}
            className="absolute left-0 top-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg z-10"
          >
            <ChevronLeft className="text-purple-600" />
          </button>
          <div
            className={`bg-white rounded-xl shadow-lg p-8 mx-12 transition-all duration-300 ease-in-out ${
              isQuoteFading ? 'opacity-0 transform -translate-y-4' : 'opacity-100 transform translate-y-0'
            }`}
          >
            <p className="text-lg italic mb-4">{quotes[currentQuote].text}</p>
            <p className="text-gray-600">- {quotes[currentQuote].author}</p>
          </div>
          <button
            onClick={() => handleQuoteChange('next')}
            className="absolute right-0 top-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg z-10"
          >
            <ChevronRight className="text-purple-600" />
          </button>
        </div>
      ) : (
        <div className="grid md:grid-cols-3 gap-6">
          {quotes.map((quote, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-lg p-8 transform hover:scale-105 transition-transform"
            >
              <p className="text-lg italic mb-4">{quote.text}</p>
              <p className="text-gray-600">- {quote.author}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  </section>
);

export { quotes };
export default QuotesSection;