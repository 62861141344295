import React from 'react';
import { Beaker, Brain, Sparkles } from 'lucide-react';

const FeaturesSection = () => (
  <section className="py-16">
    <div className="container mx-auto px-6">
      <div className="grid md:grid-cols-3 gap-8">
        <div className="bg-white p-6 rounded-xl shadow-lg text-center">
          <div className="flex justify-center mb-4">
            <Beaker size={48} className="text-purple-600" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Fun Experiments</h3>
          <p className="text-gray-600">Hands-on learning that makes science exciting!</p>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-lg text-center">
          <div className="flex justify-center mb-4">
            <Brain size={48} className="text-purple-600" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Interactive Learning</h3>
          <p className="text-gray-600">No boring lectures - we learn by doing!</p>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-lg text-center">
          <div className="flex justify-center mb-4">
            <Sparkles size={48} className="text-purple-600" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Amazing Discoveries</h3>
          <p className="text-gray-600">Every class is a new adventure!</p>
        </div>
      </div>
    </div>
  </section>
);

export default FeaturesSection;