import React from 'react';
import { Mail, Phone } from 'lucide-react';
import { Turnstile } from '@marsidev/react-turnstile';

const ContactSection = ({
  handleInputChange,
  handleSubmit,
  formStatus,
  isTurnstileVerified,
  turnstileError,
  handleTurnstileChange,
  handleTurnstileError,
  handleTurnstileExpired,
  refTurnstile
}) => (
  <section id="contact" className="py-16">
    <div className="container mx-auto px-6">
      <div className="grid md:grid-cols-2 gap-12">
        <div>
          <h2 className="text-3xl font-bold mb-6">Get in Touch</h2>
          <div className="space-y-4">
            <div className="flex items-center">
              <Mail className="text-purple-600 mr-4" />
              <span>info@atomicclasses.com</span>
            </div>
            <div className="flex items-center">
              <Phone className="text-purple-600 mr-4" />
              <span>+91 00000 00000</span>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-lg p-8">
          <form className="space-y-4" onSubmit={handleSubmit} noValidate>
            <div>
              <input
                type="text"
                name="parentName"
                onChange={handleInputChange}
                placeholder="Parent's Name *"
                className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                required
              />
            </div>
            <div>
              <input
                type="text"
                name="childName"
                onChange={handleInputChange}
                placeholder="Child's Name *"
                className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                required
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                onChange={handleInputChange}
                placeholder="Email *"
                className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                required
              />
            </div>
            <div>
              <input
                type="tel"
                name="phone"
                onChange={handleInputChange}
                placeholder="Phone (10 digits) *"
                className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                required
                pattern="[6-9][0-9]{9}"
                title="Please enter a valid 10-digit phone number"
              />
            </div>
            <div>
              <textarea
                name="message"
                onChange={handleInputChange}
                placeholder="Message *"
                className="w-full p-3 border rounded h-32 focus:outline-none focus:ring-2 focus:ring-purple-600"
                required
              />
            </div>

            <div className="mt-4">
              <Turnstile
                id="turnstile-1"
                ref={refTurnstile}
                siteKey="0x4AAAAAAA0xMHI_MqOyD63d"
                onVerify={handleTurnstileChange}
                onError={handleTurnstileError}
                onExpire={handleTurnstileExpired}
              />
              {turnstileError && (
                <p className="text-red-500 text-sm mt-2">{turnstileError}</p>
              )}
            </div>

            {formStatus.error && (
              <div className="bg-red-50 text-red-500 p-3 rounded">
                {formStatus.error}
              </div>
            )}

            {formStatus.success && (
              <div className="bg-green-50 text-green-500 p-3 rounded">
                Thank you for your message! We'll get back to you soon.
              </div>
            )}

            <button
              type="submit"
              disabled={formStatus.loading || !isTurnstileVerified}
              className={`w-full px-6 py-3 rounded transition-colors ${
                formStatus.loading || !isTurnstileVerified
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-purple-600 hover:bg-purple-700'
              } text-white`}
            >
              {formStatus.loading ? 'Sending...' : 'Send Message'}
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
);

export default ContactSection;