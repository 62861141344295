import React from 'react';
import { Star, Rocket, Atom, Calculator, Microscope } from 'lucide-react';

const HeroSection = () => (
  <section 
    id="home" 
    className="relative py-24 bg-gradient-to-r from-purple-100 to-blue-100 overflow-hidden"
  >
    {/* Starry background */}
    <div className="absolute inset-0 opacity-20 pointer-events-none">
      <div className="absolute top-0 left-0 w-full h-full bg-[length:100px_100px] 
        bg-[radial-gradient(#9333ea_1px,transparent_1px)] 
        [mask-image:radial-gradient(ellipse_at_center,transparent_30%,black)]">
      </div>
      {/* Multiple stars */}
      {[...Array(20)].map((_, i) => (
        <Star 
          key={i} 
          className="absolute text-yellow-400 opacity-50 animate-twinkle" 
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            width: `${Math.random() * 20 + 10}px`,
            height: `${Math.random() * 20 + 10}px`,
            animationDelay: `${Math.random() * 2}s`
          }}
        />
      ))}
    </div>
    
    {/* Floating scientific icons */}
    <div className="absolute top-10 left-10 animate-bounce">
      <Atom className="text-green-500 w-16 h-16 opacity-70" />
    </div>
    <div className="absolute top-10 right-10 animate-pulse">
      <Rocket className="text-blue-500 w-14 h-14 opacity-70" />
    </div>
    <div className="absolute bottom-10 left-10 animate-spin">
      <Calculator className="text-red-500 w-12 h-12 opacity-60" />
    </div>
    <div className="absolute bottom-10 right-10 animate-bounce">
      <Microscope className="text-purple-500 w-12 h-12 opacity-60" />
    </div>

    {/* Content container */}
    <div className="container mx-auto px-6 text-center relative z-10">
      <h1 className="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600 mb-6 drop-shadow-lg">
        Science Explorers Welcome!
      </h1>
      <p className="text-2xl text-gray-700 mb-10 font-medium tracking-wide">
        Discover the Wonders of Chemistry, Math, Biology, Physics, and More!
      </p>
      <a 
        href="#contact" 
        className="inline-flex items-center justify-center bg-purple-600 text-white px-10 py-4 rounded-full 
        hover:bg-purple-700 transition-all duration-300 
        transform hover:scale-105 hover:shadow-lg 
        font-bold tracking-wider"
      >
        Start Your Scientific Adventure!
        <Rocket className="ml-3 w-6 h-6 animate-pulse" />
      </a>
    </div>

    {/* Custom Tailwind animation for twinkling stars */}
    <style jsx>{`
      @keyframes twinkle {
        0%, 100% { opacity: 0.5; }
        50% { opacity: 1; }
      }
      .animate-twinkle {
        animation: twinkle 2s infinite;
      }
    `}</style>
  </section>
);

export default HeroSection;