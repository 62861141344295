import React from 'react';

const AboutSection = () => (
  <section id="about" className="py-16 bg-gray-50">
    <div className="container mx-auto px-6">
      <div className="grid md:grid-cols-2 gap-12 items-center">
        <div className="flex justify-center">
          <img
            src="priyanka.jpg"
            alt="Dr. Priyanka Ohri Chandna"
            className="rounded-full w-64 h-64 object-cover shadow-lg border-4 border-gray-200"
          />
        </div>

        <div>
          <h2 className="text-4xl font-extrabold text-gray-800 mb-6">Meet Dr. Priyanka Ohri Chandna</h2>
          <p className="text-gray-700 text-lg leading-relaxed mb-6">
            Dr. Chandna, a Ph.D. in Chemistry specializing in Environmental Chemistry, brings over a decade of teaching experience to inspire young minds.
          </p>
          <p className="text-gray-700 text-lg leading-relaxed mb-6">
            Understanding that every child is curious by nature, Dr. Chandna's approach blends real-world examples with hands-on exploration, helping students see how chemistry connects to everyday life and the environment around them. Their teaching goes beyond textbooks, focusing on sparking curiosity, building confidence, and laying a strong foundation for lifelong learning.
          </p>
          <p className="text-gray-700 text-lg leading-relaxed mb-6">
            Parents can trust that their children are in nurturing hands, guided by an expert who is as passionate about teaching as they are about watching students grow. Let's turn learning into an adventure, one experiment at a time!
          </p>
          <ul className="space-y-2 text-gray-800 text-lg font-medium">
            <li>✓ <span className="text-gray-600">Specialized in making complex concepts simple</span></li>
            <li>✓ <span className="text-gray-600">Interactive teaching methodology</span></li>
            <li>✓ <span className="text-gray-600">Focus on practical applications</span></li>
            <li>✓ <span className="text-gray-600">Proven track record of student success</span></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default AboutSection;