import React from 'react';

const Navigation = () => (
  <nav className="bg-white shadow-md">
    <div className="container mx-auto px-6 py-4">
      <div className="flex items-center justify-between">
        <div className="text-2xl font-bold text-purple-600">Atomic Classes</div>
        <div className="hidden md:flex space-x-8">
          <a href="#home" className="text-gray-600 hover:text-purple-600">Home</a>
          <a href="#about" className="text-gray-600 hover:text-purple-600">About</a>
          <a href="#testimonials" className="text-gray-600 hover:text-purple-600">Testimonials</a>
          <a href="#contact" className="text-gray-600 hover:text-purple-600">Contact</a>
        </div>
      </div>
    </div>
  </nav>
);

export default Navigation;