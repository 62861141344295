import React, { useState, useEffect, useRef } from 'react';
import Navigation from './components/Navigation';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import QuotesSection, {quotes} from './components/QuotesSection';
import AboutSection from './components/AboutSection';
import TestimonialsSection, {testimonials} from './components/TestimonialsSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';

function App() {
  const [currentQuote, setCurrentQuote] = useState(0);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isQuoteFading, setIsQuoteFading] = useState(false);
  const [isTestimonialFading, setIsTestimonialFading] = useState(false);
  
  const formRef = useRef({
    parentName: '',
    childName: '',
    email: '',
    phone: '',
    message: ''
  });
  
  const [formStatus, setFormStatus] = useState({
    loading: false,
    error: null,
    success: false
  });
  
  const [isTurnstileVerified, setIsTurnstileVerified] = useState(false);
  const refTurnstile = useRef();
  const turnstileTokenRef = useRef(null);
  const [turnstileError, setTurnstileError] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleQuoteChange = (direction) => {
    setIsQuoteFading(true);
    setTimeout(() => {
      if (direction === 'next') {
        setCurrentQuote((prev) => (prev + 1) % quotes.length);
      } else {
        setCurrentQuote((prev) => (prev - 1 + quotes.length) % quotes.length);
      }
      setIsQuoteFading(false);
    }, 300);
  };

  const handleTestimonialChange = (direction) => {
    setIsTestimonialFading(true);
    setTimeout(() => {
      if (direction === 'next') {
        setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
      } else {
        setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
      }
      setIsTestimonialFading(false);
    }, 300);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formRef.current[name] = value;
  };

  const handleTurnstileChange = (token) => {
    turnstileTokenRef.current = token;
    setTurnstileError(null);
    setIsTurnstileVerified(true);
  };

  const handleTurnstileError = (error) => {
    console.error('Turnstile error:', error);
    setTurnstileError('Failed to verify. Please try again.');
    turnstileTokenRef.current = null;
    setIsTurnstileVerified(false);
  };

  const handleTurnstileExpired = () => {
    setTurnstileError('Verification expired. Please verify again.');
    turnstileTokenRef.current = null;
    setIsTurnstileVerified(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = formRef.current;

    setFormStatus({
      loading: true,
      error: null,
      success: false
    });

    const requiredFields = ['parentName', 'childName', 'email', 'phone', 'message'];
    const emptyFields = requiredFields.filter(field => !formData[field]);

    if (emptyFields.length > 0) {
      setFormStatus({
        loading: false,
        error: 'All fields are required',
        success: false
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setFormStatus({
        loading: false,
        error: 'Please enter a valid email address',
        success: false
      });
      return;
    }

    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(formData.phone)) {
      setFormStatus({
        loading: false,
        error: 'Please enter a valid 10-digit phone number',
        success: false
      });
      return;
    }

    if (!turnstileTokenRef.current) {
      setFormStatus({
        loading: false,
        error: 'Please complete the verification',
        success: false
      });
      return;
    }

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          turnstileToken: turnstileTokenRef.current
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      setFormStatus({
        loading: false,
        error: null,
        success: true
      });

      e.target.reset();
      formRef.current = {
        parentName: '',
        childName: '',
        email: '',
        phone: '',
        message: ''
      };
      turnstileTokenRef.current = null;
      setIsTurnstileVerified(false);
      refTurnstile.current?.reset();

    } catch (error) {
      setFormStatus({
        loading: false,
        error: 'Failed to submit form. Please try again.',
        success: false
      });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-purple-50">
      <Navigation />
      <HeroSection />
      <FeaturesSection />
      <QuotesSection
        isMobile={isMobile}
        currentQuote={currentQuote}
        isQuoteFading={isQuoteFading}
        handleQuoteChange={handleQuoteChange}
      />
      <AboutSection />
      <TestimonialsSection
        isMobile={isMobile}
        currentTestimonial={currentTestimonial}
        isTestimonialFading={isTestimonialFading}
        handleTestimonialChange={handleTestimonialChange}
      />
      <ContactSection
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        formStatus={formStatus}
        isTurnstileVerified={isTurnstileVerified}
        turnstileError={turnstileError}
        handleTurnstileChange={handleTurnstileChange}
        handleTurnstileError={handleTurnstileError}
        handleTurnstileExpired={handleTurnstileExpired}
        refTurnstile={refTurnstile}
      />
      <Footer />
    </div>
  );
}

export default App;